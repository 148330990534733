<template>

  <v-container fluid class="pa-0">
    <!-- Profile Header -->

    <v-card class="profile-header" flat rounded="0">
      <div class="profile-cover">
        <!-- Payment Status Card -->
        <v-card
          v-if="!loading && user.endOfSubscription && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(user.type)"
          class="subscription-alert ma-4 relative z-1 " elevation="2" :color="user.expired > 5 ? '' : 'error lighten-5'"
          @click="$router.push('/pago')">
          <v-card-text class="py-2">
            <template v-if="user.expired >= 0">
              <div class="d-flex align-center justify-space-between">
                <span class="d-flex align-center">
                  <v-icon :color="user.expired > 5 ? 'warning' : 'error'" class="mr-2">
                    mdi-information-outline
                  </v-icon>
                  <span :class="user.expired > 5 ? '' : 'error--text'">
                    Vence {{ user.expired >= 1 ? `en ${user.expired} días` : 'Hoy' }}
                  </span>
                </span>
                <v-btn small color="success" elevation="2" class="px-4">
                  <v-icon left small>mdi-credit-card-outline</v-icon>
                  Pagar
                </v-btn>
              </div>
            </template>





          </v-card-text>
        </v-card>


        <v-card @click="$router.push('/pago')"
          v-if="!loading && !user.endOfSubscription && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(user.type)"
          class="subscription-alert ma-4 relative z-1" elevation="2" color="warning lighten-5">
          <v-card-text class="py-2">
            <div class="d-flex align-center justify-space-between">
              <span class="d-flex align-center">
                <v-icon color="warning darken-2" class="mr-2">
                  mdi-information-outline
                </v-icon>
                <span class="warning--text text--darken-2">
                  Pendiente de pago
                </span>
              </span>
              <v-btn small color="warning darken-2" elevation="2" class="px-4 white--text">
                <v-icon left small>mdi-credit-card-outline</v-icon>
                Abonar Primera Cuota
              </v-btn>
            </div>
          </v-card-text>
        </v-card>



      </div>

      <!-- Profile Content -->
      <v-row no-gutters class="profile-content">
        <v-col cols="12" class="text-center">
          <v-avatar size="120" class="profile-avatar">
            <UserImage :user="user" />
          </v-avatar>

          <div v-if="!loading" class="profile-info">
            <h2 class=" mt-3 mb-1 text-h4 font-weight-medium" style="text-transform: capitalize;">
              {{ user.displayName.toLowerCase() || 'Usuario' }}
            </h2>
            <p class="grey--text  subtitle-1">
              {{ user.email }}
            </p>
          </div>
          <div v-else>
            <v-skeleton-loader type="text" class="mx-auto text-center mt-6 mb-1" width="200" />
            <v-skeleton-loader type="text" class="mx-auto text-center mb-4" width="250" />
          </div>
        </v-col>
      </v-row>
    </v-card>




    <!-- 
    <v-row class="mx-1">
      <v-col cols="6">
        <v-card class="stat-card text-center py-4" elevation="2">
          <div class="text-h4 font-weight-bold primary--text">{{ totalWorkouts }}</div>
          <div class="caption grey--text">Entrenamientos</div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="stat-card text-center py-4" elevation="2">
          <div class="text-h4 font-weight-bold success--text">{{ totalWeeks }}</div>
          <div class="caption grey--text">Semanas</div>
        </v-card>
      </v-col>

    </v-row> -->

    <v-card elevation="2" max-width="95%" rounded="lg" class="ma-4 mx-auto">
    <v-list v-if="['admin', 'superuser', 'entrenador'].includes($store.state.Auth.token.claims.type)" class="py-0">
        <v-list-item @click="$router.push('/perfil/horarios')">
          <v-list-item-icon>
            <v-icon color="primary darken-1">mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mis Horarios</v-list-item-title>
          </v-list-item-content>
          <v-icon color="grey">mdi-chevron-right</v-icon>
          
        </v-list-item>
      </v-list>
    </v-card>
    <!-- Profile Actions -->
    <v-card v-if="!loading" class="profile-actions ma-4 mx-auto" elevation="2" max-width="95%" rounded="lg">


      <v-list class="py-0">
        <v-list-item @click="dialogEditProfile = true">
          <v-list-item-icon>
            <v-icon color="primary darken-1">mdi-card-account-details</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1">
              Información Personal
            </v-list-item-title>
          </v-list-item-content>
          <v-icon color="grey">mdi-chevron-right</v-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="dialogInjuries = true">
          <v-list-item-icon>
            <v-icon color="primary darken-1">mdi-medical-bag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Lesiones</v-list-item-title>
          </v-list-item-content>
          <v-icon color="grey">mdi-chevron-right</v-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="dialogPreferences = true">
          <v-list-item-icon>
            <v-icon color="primary darken-1 ">mdi-bullseye-arrow</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Objetivos de Entrenamiento</v-list-item-title>
          </v-list-item-content>
          <v-icon color="grey">mdi-chevron-right</v-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="$router.push('/progreso')">
          <v-list-item-icon>
            <v-icon color="primary darken-1">mdi-chart-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Progreso</v-list-item-title>
          </v-list-item-content>
          <v-icon color="grey">mdi-chevron-right</v-icon>
        </v-list-item>


        <v-divider></v-divider>

        <v-list-item @click="dialogChangePassword = true">
          <v-list-item-icon>
            <v-icon color="grey darken-1">mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cambio de Contraseña</v-list-item-title>
          </v-list-item-content>
          <v-icon color="grey">mdi-chevron-right</v-icon>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="setTheme">
          <v-list-item-icon>
            <v-icon color="amber darken-2">{{ $vuetify.theme.dark ? 'mdi-weather-night' : 'mdi-weather-sunny'
              }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Apariencia</v-list-item-title>
            <v-list-item-subtitle>{{ $vuetify.theme.dark ? 'Modo oscuro' : 'Modo claro' }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-switch readonly v-model="$vuetify.theme.dark" inset dense hide-details></v-switch>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="logout" class="error--text">
          <v-list-item-icon>
            <v-icon color="error">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card class="mx-auto glass my-4" v-else max-width="400">
      <v-skeleton-loader
        type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"></v-skeleton-loader>
    </v-card>


    <!-- Achievement Cards -->
    <!--  <v-card class="mx-4 mt-4" outlined>
      <v-card-title>
        <v-icon left color="amber darken-2">mdi-trophy</v-icon>
        Logros
      </v-card-title>
      <v-card-text class="pb-0">
        <v-chip-group>
          <v-chip v-for="achievement in achievements" :key="achievement.id"
            :color="achievement.unlocked ? achievement.color : 'grey'" :outlined="!achievement.unlocked" class="ma-1">
            <v-icon left small>{{ achievement.icon }}</v-icon>
            {{ achievement.name }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card> -->



    <!-- User Information Card -->
    <v-dialog v-model="dialogEditProfile" max-width="500">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-information-outline</v-icon>
          Información Personal

          <v-spacer></v-spacer>
          <v-btn icon @click="dialogEditProfile = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text v-if="user">
          <v-list>
            <v-list-item v-if="user.email">
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="user.phoneNumber">
              <v-list-item-icon>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Teléfono</v-list-item-title>
                <v-list-item-subtitle>{{ user.phoneNumber.replace('+598', '0') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="user.address">
              <v-list-item-icon>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Dirección</v-list-item-title>
                <v-list-item-subtitle>{{ user.address }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="user.birthDate">
              <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Fecha de Nacimiento</v-list-item-title>
                <v-list-item-subtitle>{{ user.birthDate }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="user.medicalService">
              <v-list-item-icon>
                <v-icon>mdi-hospital-building</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Servicio Médico</v-list-item-title>
                <v-list-item-subtitle>{{ user.medicalService }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text color="primary" block @click="dialogEditProfile = false">
            Volver
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- Preferences dialog -->
    <v-dialog v-model="dialogPreferences" max-width="500" v-if="user">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-bullseye-arrow</v-icon>
          Objetivos de Entrenamiento
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <!-- Objectives Section -->

          <UserObjectives :isCoachView="false" :user="user" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text color="primary" block @click="dialogPreferences = false">
            volver
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- Injuries dialog -->
    <v-dialog v-model="dialogInjuries" max-width="500" v-if="user">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-medical-bag</v-icon>
          Historial de Lesiones
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogInjuries = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <UserInjuries :user="user" :isCoachView="false" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Password dialog -->
    <v-dialog v-model="dialogChangePassword" max-width="500" v-if="user">
      <v-card>
        <v-card-title>
          Cambio de Contraseña
          <v-spacer></v-spacer>
          <v-btn icon small @click="dialogChangePassword = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-subtitle class="my-4">
          Por favor ingresa tu contraseña actual y la nueva contraseña que deseas utilizar.
        </v-card-subtitle>

        <v-card-text>
          <v-form ref="passchange">
            <v-text-field v-model="currentPassword" :rules="passwordRules" autocomplete="current-password"
              :disabled="loading" :error-messages="errors" label="Contraseña actual" name="password" outlined dense
              :type="showPassword ? 'text' : 'password'" prepend-inner-icon="mdi-lock-outline" class="mb-4">
              <template v-slot:append>
                <v-btn icon small @click="showPassword = !showPassword">
                  <v-icon>{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>

            <v-divider class="mb-4"></v-divider>

            <v-text-field v-model="password" :rules="passwordRules" autocomplete="new-password" :disabled="loading"
              :error-messages="errors" label="Nueva contraseña" name="password" outlined dense
              :type="showPassword ? 'text' : 'password'" prepend-inner-icon="mdi-lock" class="mb-2">
              <template v-slot:append>
                <v-btn icon small @click="showPassword = !showPassword">
                  <v-icon>{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>

            <v-text-field v-model="password2"
              :rules="[...passwordRules, (v) => v === password || 'Las contraseñas no coinciden']"
              autocomplete="new-password" :disabled="loading" :error-messages="errors"
              label="Confirmar nueva contraseña" name="password2" outlined dense
              :type="showPassword ? 'text' : 'password'" prepend-inner-icon="mdi-lock-check">
              <template v-slot:append>
                <v-btn icon small @click="showPassword = !showPassword">
                  <v-icon>{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogChangePassword = false" :disabled="loading" class="mr-2">
            Cancelar
          </v-btn>
          <v-btn color="success" :loading="loadingConfirm" @click="changePassword" elevation="2">
            <v-icon left>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <LicsenceManagerVue :user="user"
      v-if="!loading && user && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(user.type)">
    </LicsenceManagerVue>


  </v-container>
</template>

<script>
import {
  getFirestore,
  doc,
  getDoc,
  addDoc,
  collection,
  Timestamp,
} from 'firebase/firestore';
import { getAuth, updatePassword } from 'firebase/auth';
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

import LoginManager from '../models/login.js';
import moment from 'moment';

import LicsenceManagerVue from '@/components/LicsenceManager.vue';
import User from '@/models/user.js';

import UserImage from '@/components/profile/UserImage.vue';
import UserObjectives from '@/components/training/UserObjectives.vue';
import UserInjuries from '@/components/training/UserInjuries.vue';
import Preferences from '@/components/training/Preferences.vue';

export default {
  name: 'UserProfile',
  components: {
    LicsenceManagerVue,
    UserImage,
    Preferences,
    UserObjectives,
    UserInjuries
  },
  async mounted() {
    this.$vuetify.goTo(0);
    const db = getFirestore();
    let user = this.$store.state.Auth.token.claims.user_id;
    const docRef = doc(db, `users/${user}`);
    const docSnap = await getDoc(docRef);
    this.user = new User({
      id: docSnap.id,
      ...docSnap.data(),
    });

    if (this.user.disabled) {
      let today = moment().toDate();
      await this.setAlert('user_disabled', today, null);
      this.$notify({
        group: 'feedback',
        title: 'Error',
        type: 'error',
        text: 'Tu usuario ha sido deshabilitado, contacta con el administrador.',
      });
      this.logout();
    }

    if (this.user.endOfSubscription) {
      let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000);
      let today = moment().toDate();
      if (endOfSubscription < today) {
        await this.setAlert('user_subscription_expired', today);
        this.$router.push({ name: 'suscripcion' });
      } else {
        let diff = endOfSubscription.getTime() - today.getTime();
        let days = Math.ceil(diff / (1000 * 3600 * 24));
        if (days <= 5) {
          this.alert = days;
        }
      }
    }
    this.initials = this.getInitials(this.user.displayName);
    this.loading = false;

    let url = new URL(window.location.href);
    let changePassword = url.searchParams.get('changePassword');
    if (changePassword) {
      this.dialogChangePassword = true;
    }
  },
  methods: {
    async setAlert(type, date, description) {
      const db = getFirestore();

      let user_id = this.$store.state.Auth.token.claims.user_id;

      try {
        const timestampDate = Timestamp.fromDate(new Date(date));

        const newAlert = {
          user_id: user_id,
          type: type,
          date: timestampDate,
          description: description,
          seen: false,
        };

        await addDoc(collection(db, 'alerts'), newAlert);

        const alertRef = collection(db, `users/${user_id}/alerts`);
        await addDoc(alertRef, newAlert);
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    },
    logout() {
      LoginManager.logout();
    },
    getInitials(displayName) {
      let arr = displayName.split(' ');
      if (arr.length === 1) return arr[0].charAt(0).toUpperCase();
      else return (arr[0][0] + arr[1][0]).toUpperCase();
    },
    setTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.dispatch('changeTheme', this.$vuetify.theme.dark);
    },
    setColor(color) {
      this.$vuetify.theme.themes.dark.primary = color;
      this.$vuetify.theme.themes.light.primary = color;
      this.$store.dispatch('changePrimary', color);
    },
    async changePassword() {
      this.$refs.passchange.validate();
      if (this.$refs.passchange.validate()) {
        this.loadingConfirm = true;
        const auth = getAuth();

        const user = auth.currentUser;

        try {
          const credential = EmailAuthProvider.credential(user.email, this.currentPassword);

          let result_re_auth = await reauthenticateWithCredential(user, credential);
          if (!result_re_auth) {
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'La contraseña actual no es correcta',
              type: 'error',
            });
            this.loadingConfirm = false;
            return;
          }

          const newPassword = this.password;
          await updatePassword(user, newPassword);

          this.password = '';
          this.password2 = '';
          this.dialogChangePassword = false;
          this.loadingConfirm = false;

          this.$notify({
            group: 'feedback',
            title: 'Contraseña cambiada',
            text: 'Tu contraseña ha sido cambiada con éxito',
            type: 'success',
          });

          this.$router.push('/inicio');
        } catch (error) {
          if (error.code == 'auth/wrong-password') {
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'La contraseña actual no es correcta',
              type: 'error',
            });
          } else if (error.code == 'auth/weak-password') {
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'La contraseña debe tener al menos 6 caracteres',
              type: 'error',
            });
          } else if (error.code == 'auth/requires-recent-login') {
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'Debes volver a iniciar sesión para cambiar la contraseña',
              type: 'error',
            });
          } else {
            this.$notify({
              group: 'feedback',
              title: 'Error',
              text: 'Hubo un error al cambiar la contraseña',
              type: 'error',
            });
          }
          this.loadingConfirm = false;
          console.log(error);
        }
      }
    },
  },
  data() {
    return {
      alert: false,
      dialogChangePassword: false,
      initials: '',
      loading: true,
      loadingConfirm: false,
      user: null,
      colorGroups: ['#0ab1ec', '#414141', '#4c4faf', '#af4d4d', '#9C27B0', '#795548'],
      language: this.$store.state.lang == 'es' ? 0 : 1,
      password: '',
      password2: '',
      currentPassword: '',
      errors: [],
      passwordRules: [
        (v) => !!v || 'La contraseña es requerida',
        (v) => v.length >= 6 || 'La contraseña debe tener al menos 6 caracteres',
      ],
      showPassword: true,
      dialogEditProfile: false,
      achievements: [
        {
          id: 1,
          name: 'Primer Mes',
          icon: 'mdi-star',
          color: 'amber darken-2',
          unlocked: true
        },
        {
          id: 2,
          name: '50 Entrenamientos',
          icon: 'mdi-run',
          color: 'light-blue',
          unlocked: true
        },
        {
          id: 3,
          name: 'Racha 7 días',
          icon: 'mdi-fire',
          color: 'deep-orange',
          unlocked: true
        },
        {
          id: 4,
          name: 'Experto',
          icon: 'mdi-medal',
          color: 'purple',
          unlocked: false
        },
      ],
      totalWorkouts: 0,
      totalWeeks: 0,
      dialogPreferences: false,
      dialogInjuries: false,
    };
  },
};
</script>

<style>
#avatar-skeleton>.v-skeleton-loader__avatar {
  width: 100px;
  height: 100px;
  display: block;
  margin: auto;
}

#avatar-heading>.v-skeleton-loader__heading {
  display: block;
  margin: auto;
}

/* Additional styling */
.user-info-card .v-card-text {
  padding: 16px;
}

.user-info-card .headline {
  font-weight: bold;
  font-size: 24px;
}

.user-info-card .v-btn {
  margin-top: 8px;
}
</style>


</script>

<style scoped>
.z-1 {
  z-index: 1;
}

.profile-header {
  position: relative;
  background: transparent;

}

.profile-cover {
  height: 160px;
  background: linear-gradient(135deg, var(--v-primary-base), var(--v-secondary-base));
  position: relative;
  overflow: hidden;
  border-radius: 0 0 30px 30px;
}

.profile-cover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);
}

.profile-content {
  position: relative;
  margin-top: -60px;
}

.profile-avatar {
  border: 4px solid white;
  background: grey;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.profile-avatar:hover {
  transform: scale(1.05);
}

.profile-info {
  animation: fadeIn 0.5s ease;
}

.subscription-alert {
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.subscription-alert:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.profile-actions {
  transition: transform 0.2s ease;
}

.profile-actions:hover {
  transform: translateY(-2px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>